$color-primary: #c0001e;
$color-primary-light: #ff0036;
$color-primary-dark: #780012;

$color-secondary: #012938;
$color-secondary-light: #357d9c;
$color-secondary-dark: #02384f;

$color-accent: #9aeba3;
$color-accent-light: #dafdba;
$color-accent-dark: #63c96f;

$color-grey-1: #262626;
$color-grey-2: #424142;
$color-grey-3: #7f7f7f;
$color-grey-4: #b6b6b6;
$color-grey-5: #d7d7d7;
$color-grey-6: #f5f5f5;

$color-white: #fff;
$color-black: #000;
$color-dark: #012030;
$color-background: $color-white;

$color-whatsapp-dark: #128c7e;
$color-whatsapp-light: #25d366;

$color-text-body: $color-grey-2;
$color-text-muted: $color-grey-4;

$color-text-titles: $color-secondary-dark;

$font-size-smaller: 0.8125rem;
$font-size-small: 0.9rem;
$font-size: 1rem;
$font-size-big: 1.125rem;
$font-size-bigger: 1.25rem;

$font-weight-light: 200;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 600;

$link: $color-accent;

$box-shadow-small: 0px 0px 10px #00000029;
$box-shadow: 0px 8px 16px rgba(30, 0, 40, 0.08) !important;
$box-shadow-large: 0px 16px 48px rgba(30, 0, 40, 0.1);
$box-shadow-header: 0px 9px 10px #00000029;

$border-radius-min: 0.3rem;
$border-radius: 0.625rem;
$border-radius-cta: 2.5rem;

$trans: 0.2s all ease-in-out;

$header-1: 3rem;
$header-1-res: 2.18rem;
$header-2: 2.1875rem;
$header-2-res: 1.875rem;
$header-3: 1.5rem;
$header-3-res: 1.25rem;
$header-4: 1.25rem;
$header-5: 1rem;
$header-6: 0.87rem;

$display-1: 6rem;
$display-2: 4.35rem;
$display-3: 3.45rem;
$display-4: 2.18rem;

$aa: 2.1875rem;

$space-0: 0px;
$space-05: 0.5rem;
$space-01: 1rem;
$space-1: 1.5rem;
$space-2: 2.5rem;
$space-3: 4rem;
$space-4: 5rem;
$space-5: 7rem;
$space-6: 9.375rem;

$typo-primary: "Poppins";
$typo-secondary: "Poppins";

$color-marron-c: #895c30;
$color-verde-c: #009318;
$color-amarillo-c: #ffec00;
$color-azul-c: #0060ad;
$color-gris-c: #575756;
