.tags-proyectos {
  &__lista {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    @media screen and (max-width: 991px) {
      display: none;
    }
  }

  &__term {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    &:nth-last-child(1) {
      margin-right: 0;
    }
    @media screen and (max-width: 991px) {
      font-size: 0.875rem;
      font-weight: normal;
      padding: 0.4rem 1rem;
    }
    @media screen and (max-width: 575px) {
      font-size: $font-size-small;
      font-weight: bold;
      padding: 0;
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
      background-color: transparent;
      border-color: transparent;
      color: $color-primary;
    }
  }

  &__accordion {
    @media screen and (min-width: 992px) {
      display: none;
    }
    .accordion-item {
      border: none;
    }
    .accordion-button {
      border: none;
      font-weight: $font-weight-bold;
      box-shadow: none;
      padding-left: 0;
      padding-right: 0;
      @media screen and (max-width: 575px) {
        justify-content: center;
      }

      &::after {
        margin-left: $space-1;
        color: $color-primary;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23012938'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      }
    }
    .accordion-button:not(.collapsed) {
      background-color: transparent;
      color: $color-secondary;
      box-shadow: none;
      &::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23012938'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      }
    }
    .accordion-button:focus {
      border-color: transparent;
    }
  }

  &__accordion--tags {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  &__accordion--term {
    margin-right: 1.25rem;
    font-weight: $font-weight-medium;
    &:nth-last-child(1) {
      margin-right: 0;
    }
  }
}

.proyectos {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  gap: $space-3 $space-2;
  @media screen and (max-width: 1120px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  @media screen and (max-width: 767px) {
    grid-template-columns: 2fr;
    gap: $space-2;
  }

  &--1 {
    .proyectos__item:nth-last-child(1) {
      grid-column: 3 / span 2;
      @media screen and (max-width: 1120px) {
        grid-column: 2 / span 2;
      }
      @media screen and (max-width: 767px) {
        grid-column: 1 / span 2;
      }
    }
  }

  &--2 {
    .proyectos__item:nth-last-child(2) {
      grid-column: 2 / span 2;
      @media screen and (max-width: 1120px) {
        grid-column: 1 / span 2;
      }
      @media screen and (max-width: 767px) {
        grid-column: 1 / span 2;
      }
    }
    .proyectos__item:nth-last-child(1) {
      grid-column: 4 / span 2;
      @media screen and (max-width: 1120px) {
        grid-column: 3 / span 2;
      }
      @media screen and (max-width: 767px) {
        grid-column: 1 / span 2;
      }
    }
  }

  &__item {
    grid-column: span 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    @media screen and (max-width: 575px) {
      align-items: center;
      text-align: center;
    }
    .lista-tags {
      @media screen and (max-width: 575px) {
        justify-content: center;
      }
    }
  }

  &__img {
    margin-bottom: 1rem;
    img {
      aspect-ratio: 16/11;
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  &__titulo {
    font-size: $font-size-bigger;
    color: $color-text-titles;
  }
}

.proyectos-relacionados {
  background-color: $color-grey-6;

  &__content {
  }
}
