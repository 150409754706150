.contacto {
  &__content {
    color: $color-grey-3;
  }

  &__item {
    display: grid;
    grid-template-columns: 2fr 1fr;
    margin-bottom: 8rem;
    &--form {
      grid-template-columns: 1fr 2fr;
      margin-bottom: 6rem;
      .contacto__col1 {
        @media screen and (max-width: 991px) {
          margin-bottom: 3rem;
        }
      }
    }
    @media screen and (max-width: 991px) {
      display: flex;
      flex-direction: column;
      margin-bottom: 4rem;
    }
    &:nth-last-child(1) {
      margin-bottom: 0;
    }

    &--presupuesto {
      display: flex;
      justify-content: center;
      .contacto__col2 {
        padding-left: 0;
        width: 60%;
        @media screen and (max-width: 991px) {
          width: 100%;
        }
      }
    }
  }

  &__col1 {
  }

  &__col2 {
    padding-left: 5rem;
    display: flex;
    flex-direction: column;
    color: $color-grey-3;

    @media screen and (max-width: 991px) {
      padding-left: 0;
    }
  }

  &__direccion {
    margin-bottom: 0.6875rem;
    line-height: 1.5;
  }

  &__descripcion {
    margin-bottom: 2rem;
    line-height: 1.8;
  }

  &__telefonos {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    font-size: $font-size;
  }

  &__telefonos-item {
    display: flex;
    margin-bottom: 0.2rem;
    .bi {
      margin-right: 0.5rem;
    }
    &:nth-last-child(1) {
      margin-top: 0.25rem;
      margin-bottom: 0;
    }
  }

  &__telefonos-telefono {
    color: $color-grey-3;
    text-decoration: none;
    &:hover {
      color: $color-grey-2;
    }
  }

  &__horario {
    font-size: $font-size-small;
    strong {
      color: $color-secondary-dark;
    }
  }
}

.mapa {
  background-color: $color-secondary;
  iframe {
    width: 100%;
  }
}

.page-template-page-presupuestos .contacto__descripcion {
  margin-bottom: 0;
  p {
    margin-bottom: 0;
  }
}
