.pre-header {
  background-color: $color-dark;
  padding: 0.25rem 0;
  @media screen and (max-width: 575px) {
    padding-right: 0;
    padding-left: 0;
  }
  &__container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media screen and (max-width: 991px) {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 1rem;
    }
    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  &__item {
    margin-right: $space-1;
    @media screen and (max-width: 991px) {
      margin-right: 0;
      justify-self: center;
    }
    &:nth-last-child(1) {
      margin-right: 0;

      @media screen and (max-width: 575px) {
        margin-left: auto;
      }
    }
    a {
      color: $color-white;
      &:hover {
        color: $color-primary;
      }
    }
    .boton {
      color: $color-dark;
      &:hover {
        color: $color-dark;
      }
    }

    &--telf {
      @media screen and (max-width: 991px) {
        justify-self: flex-start;
      }
      a {
        font-size: $font-size-small;
      }
    }

    &--whats {
      @media screen and (max-width: 767px) {
        justify-self: flex-end;
      }
      a {
        padding: 0.4rem 1rem;
        @media screen and (max-width: 575px) {
          padding: 0.25rem 0.75rem;
        }
      }
    }

    &--rs {
      @media screen and (max-width: 991px) {
        justify-self: flex-end;
      }
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }
  .widget-rs {
    .zoom-social-icons-list {
      margin-left: -10px !important;
    }
    .social-icon {
      font-size: 20px !important;
      color: $color-white !important;
      padding: 5px !important;
      @media screen and (max-width: 767px) {
        font-size: 18px !important;
      }
      &:hover {
        color: $color-primary !important;
      }
    }
  }
}

.header {
  padding: 1.4375rem 0;
  background-color: $color-background;
  z-index: 3;
  box-shadow: $box-shadow-header;
  transition: $trans;
  @media screen and (max-width: 991px) {
    padding: 1rem 1.2rem;
    transition: $trans;
  }
  @media screen and (max-width: 767px) {
    padding: 0.75rem 1rem;
    transition: $trans;
  }
  @media screen and (max-width: 575px) {
    padding: 0.5rem 0rem;
    transition: $trans;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &--home {
  }

  &--sticky {
    position: sticky;
    top: 0;
    background-color: $color-white;
    box-shadow: $box-shadow;
    padding: 1rem 2rem;
    transition: $trans;
    @media screen and (max-width: 991px) {
      padding: 0.8rem 1.2rem;
    }
  }

  &__logo {
    margin-right: 2rem;
    img {
      width: 210px;
      height: auto;
      @media screen and (max-width: 991px) {
        width: 180px;
      }
    }
  }

  &__nav {
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      margin-left: 0;
    }
    @media screen and (max-width: 991px) {
      display: none;
    }
    &--left {
      margin-right: auto;
    }
  }

  &__burger {
    margin-left: auto;
    button {
      border: none;
      background: none;
    }
    i {
      color: $color-dark;
      font-size: 2.3rem;
    }
    @media screen and (min-width: 992px) {
      display: none;
    }
  }
}
