.hero {
  position: relative;
  overflow: hidden;

  &__video {
    position: absolute;
    z-index: 1;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__overlay {
    z-index: 2;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: $color-dark;
    opacity: 0.2;
  }

  &__container {
    display: flex;
    align-items: flex-end;
    height: 100%;
  }

  &__content {
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    width: 85%;
    padding-top: $space-6;
    padding-bottom: $space-5;
    color: $color-white;

    @media screen and (max-width: 991px) {
      width: 90%;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    @media screen and (max-width: 575px) {
      padding-top: $space-4;
      padding-bottom: $space-3;
    }
  }

  &__pretitulo {
    font-size: $font-size-bigger;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    margin-bottom: 1rem;
    @media screen and (max-width: 575px) {
      font-size: 1.15rem;
    }
  }

  &__titulo {
    font-size: $display-2;
    font-weight: $font-weight-bold;
    line-height: 1.2;
    margin-bottom: 0;
    @media screen and (max-width: 1281px) {
      font-size: 3.5rem;
    }
    @media screen and (max-width: 991px) {
      font-size: 3.2rem;
    }
    @media screen and (max-width: 767px) {
      font-size: 2.3rem;
    }
    @media screen and (max-width: 575px) {
      margin-bottom: 0;
    }
    @media screen and (max-width: 480px) {
      font-size: 1.9rem;
    }
  }

  &__description {
    font-weight: $font-weight-medium;
    margin-bottom: 3rem;
  }

  &__actions {
    .boton--primary {
      margin-right: 1rem;
      @media screen and (max-width: 575px) {
        margin-right: 0;
        margin-bottom: 1rem;
      }
    }
  }

  &__img {
    width: 100%;
    height: auto;
    padding: 2rem;
  }
}

.clientes {
  &__listado {
    // display: grid;
    // grid-template-columns: repeat(6, minmax(0, 1fr));
    // gap: 5rem;
    // align-items: center;
    // margin-right: 3rem;
    // margin-left: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem 4rem;
    @media screen and (max-width: 480px) {
      gap: 2rem 3rem;
    }
    // @media screen and (max-width: 991px) {
    //   grid-template-columns: repeat(3, minmax(0, 1fr));
    //   gap: 3rem 5rem;
    // }
    // @media screen and (max-width: 575px) {
    //   grid-template-columns: repeat(2, minmax(0, 1fr));
    //   gap: 3rem;
    //   margin-right: 0;
    //   margin-left: 0;
    // }

    // @media screen and (max-width: 480px) {
    // }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    img {
      width: 100%;
      height: auto;
      max-width: 150px;
      max-height: 50px;
      @media screen and (max-width: 480px) {
        max-width: 126px;
        max-height: 42px;
      }
    }
  }
}

.destacado {
  background-color: $color-grey-6;

  &__servicios {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 3rem;
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
    }
  }

  &__servicio {
    display: flex;
    align-items: flex-start;
    @media screen and (max-width: 480px) {
      flex-direction: column;
    }
  }

  &__icono {
    img {
      width: auto;
      height: 54px;
      @media screen and (max-width: 991px) {
        width: 54px;
        height: auto;
      }
    }
    @media screen and (max-width: 480px) {
      margin-bottom: 1rem;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 1.875rem;
    @media screen and (max-width: 480px) {
      padding-left: 0;
    }
  }

  &__titulo {
  }

  &__descripcion {
    color: $color-grey-3;
    font-size: $font-size-small;
    line-height: 1.6;
  }
}

.s-servicios {
  background-color: $color-secondary-dark;
  &__slider {
    margin-bottom: 3rem;
  }

  &__item {
    padding: 0 1rem;
  }

  &__img {
    img {
      aspect-ratio: 16/11;
      width: 100%;
      height: auto;
      object-fit: cover;
      margin-bottom: 1rem;
    }
  }

  &__overlay {
  }

  &__titulo {
    color: $color-white;
    font-size: $font-size-bigger;
    font-weight: $font-weight-bold;
    text-align: center;
  }

  &__actions {
    display: flex;
    justify-content: center;
    gap: 2rem;
  }
}
