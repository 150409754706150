.cta {
  // background-color: $color-grey-6;
  // &--home {
  //   background-color: $color-white;
  // }
  @media screen and (max-width: 575px) {
    padding: 0 !important;
  }

  &__container {
    position: relative;
    overflow: hidden;
    max-width: 991px;
    &--ext {
      padding: 4rem 0 4.875rem 0;
      border-radius: 2rem;
      @media screen and (max-width: 575px) {
        border-radius: 0;
      }
    }
  }

  &__img {
    position: absolute;
    z-index: 1;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__overlay {
    z-index: 2;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: $color-primary-light;
    opacity: 0.6;
  }

  &__container {
    display: flex;
    justify-content: center;
    height: 100%;
  }

  &__content {
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: $color-white;
  }

  &__icono {
    width: 5.25rem;
    height: auto;
    margin-bottom: 1.25rem;
  }

  &__pretitulo {
    font-size: $header-3;
    font-weight: $font-weight-bold;
    //margin-bottom: 0.5rem;

    @media screen and (max-width: 991px) {
      font-size: 1.4rem;
    }
    @media screen and (max-width: 767px) {
      font-size: 1.3rem;
    }
    @media screen and (max-width: 575px) {
      font-size: 1.1rem;
    }
  }

  &__titulo {
    font-size: $display-4;
    font-weight: $font-weight-bold;
    //color: $color-text-titles;
    line-height: 1.2;
    margin-bottom: 1rem;
    @media screen and (max-width: 991px) {
      font-size: 2.2rem;
    }
    @media screen and (max-width: 767px) {
      font-size: 2rem;
    }
    @media screen and (max-width: 575px) {
      font-size: 1.6rem;
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
    gap: 2rem;
  }
}
