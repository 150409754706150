.footer {
  background-color: $color-secondary;
  padding: 5.2rem 0 2.3125rem 0;
  @media screen and (max-width: 767px) {
    padding: 4rem 0 2rem 0;
  }
  &__content {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 3rem;
    @media screen and (max-width: 991px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      .footer__item--logo {
        order: 3;
      }
      .footer__item--menu-info {
        order: 1;
      }
      .footer__item--menu-servicios {
        order: 2;
      }
      .footer__item--contacto {
        order: 4;
      }
    }
    @media screen and (max-width: 575px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      gap: 0;
      align-items: center;
      text-align: center;
      .footer__item--logo {
        order: 1;
      }
      .footer__item--menu-info {
        order: 2;
      }
      .footer__item--menu-servicios {
        order: 3;
      }
      .footer__item--contacto {
        order: 4;
      }
    }
  }

  &__item {
    color: white;
    @media screen and (max-width: 767px) {
      margin-bottom: 2rem;
    }
    &:nth-last-child(1) {
      margin-bottom: 0;
    }
    .widget-footer__title {
      font-size: $font-size;
      font-weight: $font-weight-bold;
      color: $color-white;
    }
    a {
      color: $color-white;
      font-size: $font-size-small;
      &:hover {
        color: $color-grey-5;
      }
    }
    ul {
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
    }
    p {
      font-size: $font-size-small;
      margin-bottom: 0;
    }
  }

  &__item--logo {
    img {
      width: 200px;
      height: auto;
      @media screen and (max-width: 767px) {
        width: 160px;
      }
    }
  }

  &__item--contacto {
    font-size: $font-size-small;
  }

  &__direccion {
    margin-bottom: 0.5rem;
  }
}

.next-gen {
  background-color: $color-secondary;
  padding-bottom: $space-01;
  @media screen and (max-width: 575px) {
    padding-bottom: $space-1;
  }
  &__content {
    display: flex;
    align-items: center;
    gap: $space-01 $space-1;
    flex-wrap: wrap;
    @media screen and (max-width: 575px) {
      display: flex;
      flex-direction: column;
      gap: $font-size / 2;
    }
  }

  &__img {
    img {
      width: 220px;
      height: auto;
      @media screen and (max-width: 575px) {
        width: 200px;
      }
    }
  }

  &__description {
    color: $color-white;
    font-size: $font-size-small;
    @media screen and (max-width: 575px) {
      text-align: center;
    }
  }
}

.copyright {
  background-color: $color-secondary;
  padding: 0 0 5.2rem 0;
  @media screen and (max-width: 767px) {
    text-align: center;
    padding: 0 0 4rem 0;
  }

  &__container {
    border-top: 1px solid $color-white;
    padding-top: 2rem;
  }

  &__copy {
    color: $color-white;
    font-size: $font-size-small;
  }
  .menu {
    list-style: none;
    padding-left: 0;
    display: flex;
    margin-bottom: 0;
    .menu-item::after {
      color: $color-white;
      content: " -\00a0";
    }
    .menu-item:nth-last-child(1)::after {
      content: "";
    }
    @media screen and (max-width: 767px) {
      justify-content: center;
    }
    a {
      color: $color-white;
      font-size: $font-size-small;
      text-decoration: none;
      &:hover {
        color: $color-grey-5 !important;
      }
    }
  }
}

.gva {
  padding: $space-1 0;
  &__logo {
    img {
      @media screen and (min-width: 992px) {
        max-width: 500px;
        height: auto;
      }
    }
  }
}
