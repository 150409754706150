.g-servicios {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  gap: $space-4 $space-3;
  gap: $space-3 $space-2;
  @media screen and (max-width: 1120px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  @media screen and (max-width: 767px) {
    grid-template-columns: 2fr;
  }

  &--1 {
    .g-servicios__servicio:nth-last-child(1) {
      grid-column: 3 / span 2;
      @media screen and (max-width: 1120px) {
        grid-column: 2 / span 2;
      }
      @media screen and (max-width: 767px) {
        grid-column: 1 / span 2;
      }
    }
  }

  &--2 {
    .g-servicios__servicio:nth-last-child(2) {
      grid-column: 2 / span 2;
      @media screen and (max-width: 1120px) {
        grid-column: 1 / span 2;
      }
      @media screen and (max-width: 767px) {
        grid-column: 1 / span 2;
      }
    }
    .g-servicios__servicio:nth-last-child(1) {
      grid-column: 4 / span 2;
      @media screen and (max-width: 1120px) {
        grid-column: 3 / span 2;
      }
      @media screen and (max-width: 767px) {
        grid-column: 1 / span 2;
      }
    }
  }

  &__servicio {
    grid-column: span 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__servicio-img {
    margin-bottom: $space-01;
    img {
      aspect-ratio: 16/ 10;
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  &__servicio-content {
    padding: 0 0.25rem;
  }

  &__servicio-title {
    font-size: $header-2-res;
    font-weight: $font-weight-bold;
    line-height: 1.4;
    color: $color-dark;
    letter-spacing: -1px;
    a {
      color: $color-dark;
      &:hover {
        color: lighten($color: $color-dark, $amount: 10%);
      }
    }
    @media screen and (max-width: 1280px) {
      font-size: $header-3;
    }
    @media screen and (max-width: 991px) {
      margin-bottom: 1rem;
    }
    @media screen and (max-width: 575px) {
      font-size: $header-3-res;
    }
  }

  &__servicio-description {
    p {
      font-size: $font-size;
      line-height: 1.5;
      color: $color-grey-3;
      @media screen and (max-width: 991px) {
        font-size: $font-size-small;
      }
    }
  }

  &__servicio-actions {
  }
}

///////////////////////////////////////////////////////////////////////////
//   Servicios Child   ////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////
.servicio {
  &__content {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    @media screen and (max-width: 991px) {
      display: flex;
      flex-direction: column;
    }
    @media screen and (max-width: 575px) {
      align-items: center;
    }
  }

  &__img {
    @media screen and (max-width: 991px) {
      margin-bottom: 2rem;
    }
    img {
      aspect-ratio: 16/11;
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  &__info {
    padding: 0 0 0 4rem;
    @media screen and (max-width: 991px) {
      padding: 0;
    }
    @media screen and (max-width: 575px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }

  &__pretitle {
    font-size: $font-size-big;
    text-transform: uppercase;
    color: $color-dark;
    font-weight: $font-weight-bold;
    margin-bottom: 0.6875rem;
    @media screen and (max-width: 991px) {
      font-size: $font-size-small;
    }
  }

  &__title {
    font-size: $header-3;
    font-weight: $font-weight-bold;
    margin-bottom: 1rem;
    line-height: 1.4;
    color: $color-dark;
    font-family: $typo-secondary;
    letter-spacing: -1px;
    @media screen and (max-width: 1440px) {
      font-size: 2rem;
    }
    @media screen and (max-width: 991px) {
      font-size: 1.6rem;
      margin-bottom: 1rem;
    }
  }

  &__listado {
    display: flex;
    flex-direction: column;
  }
  &__descripcion {
    color: $color-grey-3;
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    .bi {
      color: $color-primary;
      font-size: 2rem;
      font-weight: $font-weight-bold;
    }
  }

  &__cliente {
    font-weight: $font-weight-bold;
  }
  &__poblacion {
    margin-bottom: 2rem;
  }

  &__contenido {
    margin-bottom: 2rem;
    p {
      font-size: $font-size;
      line-height: 2.2;
      color: $color-grey-3;
      @media screen and (max-width: 991px) {
        font-size: $font-size-small;
      }
    }
  }
}

.s-galeria {
  @media screen and (max-width: 575px) {
    margin-bottom: 5px;
  }
  &__content {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-auto-rows: 1fr;
    gap: 5px;
    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media screen and (max-width: 480px) {
      grid-template-columns: 1fr;
    }
  }

  &__item {
    @media screen and (min-width: 768px) {
      &:nth-child(1) {
        grid-column: 1/3;
        grid-row: 1/3;
      }
      &:nth-child(7) {
        grid-column: 2/4;
        grid-row: 3/5;
      }
      &:nth-child(13) {
        grid-column: 3/5;
        grid-row: 5/7;
      }
      &:nth-child(17) {
        grid-column: 2/4;
        grid-row: 7/9;
      }
      &:nth-child(21) {
        grid-column: 1/3;
        grid-row: 9/11;
      }
      &:nth-child(27) {
        grid-column: 2/4;
        grid-row: 11/13;
      }
      &:nth-child(33) {
        grid-column: 3/5;
        grid-row: 13/ 15;
      }
    }
  }

  &__img {
    aspect-ratio: 16/11;
    width: 100%;
    object-fit: cover;
  }
}
