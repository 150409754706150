@import "../../dist/vendor/bootstrap/scss/bootstrap.scss";
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.3/font/bootstrap-icons.css");

@import "base/variables";
@import "base/body";
@import "base/utilities";
@import "base/typography";
@import "base/mixins";

@import "layout/header";
@import "layout/footer";
@import "layout/nav";

@import "components/storybook"; // borrar para producción
@import "components/page-title";
@import "components/buttons";
@import "components/articles";
@import "components/forms";
@import "components/cta";
@import "components/sections";

@import "pages/inicio";
@import "pages/contacto";
@import "pages/servicios";
@import "pages/empresa";
@import "pages/proyectos";
