.menu-principal {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
  align-items: center;
  li.menu-item {
    a {
      padding: 0.3125rem 1.5625rem;
      text-decoration: none;
      color: $color-dark;
      font-size: $font-size;
      font-weight: $font-weight-bold;
      @media screen and (min-width: 768px) and (max-width: 1010px) {
        padding: 0.3125rem 0.65rem;
      }
      @media screen and (min-width: 1011px) and (max-width: 1199px) {
        padding: 0.3125rem 0.7rem;
      }
      &:hover,
      &:active {
        color: $color-primary;
      }
    }
    &.current_page_item a {
      color: $color-primary;
    }
  }
  &--left {
    margin-right: auto;
  }
  &--right {
    .menu-item:nth-last-child(1) {
      @include button;
      padding: 15px 26px;
      font-size: $font-size-small;
      line-height: 1;
      background-color: $color-primary;
      border-color: $color-primary;
      transition: $trans;
      margin-left: 1.5625rem;
      &:hover {
        background-color: darken($color-primary, 5%);
        border-color: darken($color-primary, 5%);
        box-shadow: $box-shadow-small;
        transform: translateY(-2px);
        transition: $trans;
      }
      a {
        color: $color-white;
        font-weight: $font-weight-normal;
        padding: 0;

        &:hover,
        &:active {
          color: $color-white;
          transition: $trans;
        }
      }
      &.current_page_item a {
        color: $color-white;
      }
    }
  }
}

#offcanvasMenu {
  width: 100vw;
  border: none;
}

.offcanvas {
  &-body {
    background-color: $color-dark;
    button {
      display: flex;
      margin-left: auto;
      border: none;
      background: none;
      font-size: 3rem;
      line-height: 0;
      //padding-right: 2.65rem;
      //padding-top: 1rem;
      text-align: right;
      i {
        color: $color-white;
        &:hover {
          color: $color-grey-5;
        }
      }
    }
  }

  &__navbar {
    display: flex;
    flex-direction: column;
    margin-top: 1.4rem;
    align-items: center;

    @media screen and (max-width: 575px) {
      margin-top: 0;
      padding-top: 0;
      margin-bottom: $space-01;
    }
  }

  &__menu {
    width: 100%;
    //padding: 0 1.5rem;
    display: flex;
    justify-content: center;
    text-align: center;
  }
  &__nav {
    justify-content: flex-start;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    font-size: 1rem;
    li.menu-item {
      margin-bottom: 0.7rem;
      & > a {
        font-size: 2rem;
        font-weight: $font-weight-medium;
        text-decoration: none;
        color: $color-primary;
        &:hover {
          color: $color-white;
        }
        @media screen and (max-width: 767px) {
          font-size: 1.75rem;
        }
      }
    }
  }

  &__rs {
    display: flex;
    justify-content: center;
    .zoom-social-icons-list {
      margin-left: -10px !important;
    }
    .social-icon {
      font-size: 32px !important;
      color: $color-white !important;
      @media screen and (max-width: 767px) {
        font-size: 28px !important;
      }
      &:hover {
        color: $color-grey-5 !important;
      }
    }
  }
  &__logo {
    display: flex;
    width: 220px;
    margin: 2rem auto 0 auto;
    @media screen and (max-width: 575px) {
      width: 180px;
    }
  }
}
