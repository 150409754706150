.feat {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  //margin-top: 1.5rem;
  margin-bottom: $space-3;
  &:nth-last-child(1) {
    margin-bottom: 0;
  }
  @media screen and (min-width: 1400px) {
    grid-template-columns: 1.5fr 1fr;
  }
  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr;
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 3rem;
  }
  @media screen and (max-width: 575px) {
    justify-content: center;
  }
  &--inverted {
    @media screen and (min-width: 1400px) {
      grid-template-columns: 1fr 1.5fr;
    }

    .feat__img {
      order: 2;
      @media screen and (max-width: 991px) {
        order: 1;
      }
    }
    .feat__content {
      order: 1;
      padding: 0 4rem 0 0;
      @media screen and (max-width: 991px) {
        padding: 1.5rem 0;
        order: 2;
        @media screen and (max-width: 767px) {
          padding: 0.2rem 0;
        }
      }
    }
  }

  &__img {
    display: flex;
    @media screen and (max-width: 767px) {
      margin-bottom: 0.5rem;
    }

    img {
      aspect-ratio: 16/ 10;
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0 0 4rem;
    @media screen and (max-width: 991px) {
      padding: 1.5rem 0;
    }
    @media screen and (max-width: 767px) {
      padding: 0.2rem 0;
    }
    @media screen and (max-width: 575px) {
      align-items: center;
      text-align: center;
    }
  }

  &__pretitle {
    font-size: $font-size;
    text-transform: uppercase;
    color: $color-dark;
    font-weight: $font-weight-bold;
    margin-bottom: 0.6875rem;
    @media screen and (max-width: 991px) {
      font-size: $font-size-small;
    }
  }

  &__title {
    font-size: $header-2;
    font-weight: $font-weight-bold;
    line-height: 1.4;
    color: $color-dark;
    letter-spacing: -1px;
    a {
      color: $color-dark;
      &:hover {
        color: lighten($color: $color-dark, $amount: 10%);
      }
    }
    @media screen and (max-width: 1440px) {
      font-size: 2rem;
    }
    @media screen and (max-width: 991px) {
      font-size: 1.6rem;
      margin-bottom: 1rem;
    }
    @media screen and (max-width: 575px) {
      font-size: 1.5rem;
    }
  }

  &__listado {
    display: flex;
    flex-direction: column;
  }
  &__info {
    color: $color-grey-3;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    flex-direction: column;
    .bi {
      color: $color-primary;
      font-size: 2rem;
      font-weight: $font-weight-bold;
    }
  }

  &__description {
    p {
      font-size: $font-size;
      line-height: 2.2;
      color: $color-grey-3;
      @media screen and (max-width: 991px) {
        font-size: $font-size-small;
      }
    }
    &--ser {
      margin-bottom: 1.25rem;
      p {
        line-height: 1.8;
      }
      @media screen and (max-width: 575px) {
        margin-bottom: 0;
      }
    }
  }
}
