.page-title {
  padding: 4.125rem 0 3.8rem 0;
  background-color: $color-primary;
  overflow: hidden;
  @media screen and (max-width: 767px) {
    padding: 3rem 0 3rem 0;
  }

  &__pretitle {
    color: $color-white;
    font-weight: $font-weight-bold;
    font-size: $header-3;
    @media screen and (max-width: 575px) {
      font-size: $header-3-res;
    }
  }

  &__h {
    color: $color-white;
    font-weight: $font-weight-bold;
    font-size: $header-1;
    @media screen and (max-width: 575px) {
      font-size: $header-2-res;
    }
  }
  &__breadcrumbs {
    font-size: $font-size-smaller;
    color: $color-white;
    a {
      color: $color-white;
      text-decoration: none;
      font-weight: $font-weight-bold;
      &:hover {
        color: $color-grey-5;
      }
    }
  }
  &--light {
    background-color: $color-white;
    padding-bottom: 2rem;
    text-align: center;
    .page-title__pretitle {
      color: $color-primary;
    }
    .page-title__h {
      color: $color-text-titles;
    }
    .page-title__breadcrumbs {
      color: $color-text-titles;
      a {
        color: $color-text-titles;
        &:hover {
          color: darken($color-text-titles, 5%);
        }
      }
    }
  }
}
